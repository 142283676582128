@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=MedievalSharp&family=VT323&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.imagePixelated {
  image-rendering: pixelated;
}

.headshot {
  image-rendering: -webkit-optimize-contrast;
}

.user1 {
  border-image-source: url(./assets/User1.png);
  border-left-width: 30px;
  border-right-width: 30px;
  border-top-width: 15px;
  border-bottom-width: 15px;
  border-image-slice: 25% 17% 36% 35%;
  border-image-width: 23px 36px 31px 65px;
  border-image-repeat: stretch stretch;
}

.user2 {
  border-image-source: url(./assets/User2.png);
  border-left-width: 30px;
  border-right-width: 30px;
  border-top-width: 15px;
  border-bottom-width: 15px;
  border-image-slice: 25% 17% 36% 35%;
  border-image-width: 23px 36px 31px 65px;
  border-image-repeat: stretch stretch;
}

.user3 {
  border-image-source: url(./assets/User3.png);
  border-left-width: 30px;
  border-right-width: 30px;
  border-top-width: 15px;
  border-bottom-width: 15px;
  border-image-slice: 25% 17% 36% 35%;
  border-image-width: 23px 36px 31px 65px;
  border-image-repeat: stretch stretch;
}

.bot1 {
  border-image-source: url(./assets/Bot1.png);
  border-left-width: 30px;
  border-right-width: 30px;
  border-top-width: 15px;
  border-bottom-width: 15px;
  border-image-slice: 25% 17% 36% 35%;
  border-image-width: 23px 36px 31px 65px;
  border-image-repeat: stretch stretch;
}

.bot2 {
  border-image-source: url(./assets/Bot2.png);
  border-left-width: 30px;
  border-right-width: 30px;
  border-top-width: 15px;
  border-bottom-width: 15px;
  border-image-slice: 25% 17% 36% 35%;
  border-image-width: 23px 36px 31px 65px;
  border-image-repeat: stretch stretch;
}

.bot3 {
  border-image-source: url(./assets/Bot3.png);
  border-left-width: 30px;
  border-right-width: 30px;
  border-top-width: 15px;
  border-bottom-width: 15px;
  border-image-slice: 25% 17% 36% 35%;
  border-image-width: 23px 36px 31px 65px;
  border-image-repeat: stretch stretch;
}
